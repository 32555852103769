import React, { PureComponent } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import ProgramaGraphQL from "js/graphql/resolvers/programa.resolver";
import LocaionGraphQL from "js/graphql/resolvers/locacion.resolver";
import { _t } from "js/utils/TranslationService";
import { ListofChildrenField } from "./index.js";
import AuthService from "js/utils/AuthService";

class PaisField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      paises: [],
      inBackOffice:
        window.location.pathname.indexOf("admin") !== -1 ? true : false
    };
  }

  async componentDidMount() {
    const {
      client,
      isUbicacion,
      match: {
        params: { id }
      }
    } = this.props;
    const { inBackOffice } = this.state;

    // Si se esta buscando el pais desde la ubicación entoncs siempre es para el perfil de un usuario
    // En este caso hay que mostrar solamente los paises del programa
    if (isUbicacion) {
      if (!inBackOffice) {
        const paisesPrograma = AuthService.getPaisesPrograma();
        this.setState({
          paises: paisesPrograma
        });
      } else {
        await client
          .query({
            query: ProgramaGraphQL.queries.getProgramaById,
            fetchPolicy: "network-only",
            errorPolicy: "all",
            variables: {
              idPrograma: parseInt(id) || id
            }
          })
          .then(res => {
            if (res.data.getProgramas && res.data.getProgramas.itemsPagina) {
              const programa = res.data.getProgramas.itemsPagina[0];
              this.setState({
                paises: programa.paises
              });
            }
          });
      }
    } else {
      await client
        .query({
          query: LocaionGraphQL.queries.getMasterPaises,
          fetchPolicy: "cache-first",
          errorPolicy: "all"
        })
        .then(res => {
          res.data.getMasterPaises.length > 0 &&
            this.setState({
              paises: res.data.getMasterPaises
            });
        });
    }
  }

  componentWillReceiveProps(props) {
    const { changeFieldValue, formMeta } = props;
    if (formMeta.idPais && formMeta.idPais.active) {
      changeFieldValue("idZona", null);
      changeFieldValue("idLocalidad", null);
    }
  }

  render() {
    const { required, disabled, name } = this.props;
    const { paises } = this.state;

    return (
      <ListofChildrenField
        name={name || "idPais"}
        label={`${_t("País")}`}
        disabled={disabled}
        required={required}
      >
        {paises.map((item, index) => (
          <MenuItem value={parseInt(item.idPais, 10)} key={index}>
            {item.descripcion}
          </MenuItem>
        ))}
      </ListofChildrenField>
    );
  }
}

export default withApollo(withRouter(PaisField));
