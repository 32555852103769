import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "js/components/Shared/Form/styles.js";
import { reduxForm, change, getFormMeta, FieldArray } from "redux-form";
import {
  Grid,
  Button,
  Icon,
  Tooltip,
  Paper,
  Typography
} from "@material-ui/core";
import {
  InputTextField,
  ReduxTypeAhead,
  InputNumberGtZeroField
} from "js/components/Shared/Form/Fields";
import { required as requiredValidation } from "js/components/Shared/Form/validations.js";
import { withWidth, withTheme } from "@material-ui/core";

const renderDetallesVenta = ({ fields, productos, disabled }) => (
  <Grid container spacing={2}>
    {fields.map((detalle, index) => {
      return (
        <Fragment key={index}>
          <Grid item xs={12} md={11} style={{ position: "relative" }}>
            <Paper
              style={{
                width: "100%",
                padding: "15px",
                position: "relative",
                zIndex: 1
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                  <ReduxTypeAhead
                    name={`${detalle}.codigoProducto`}
                    label="Producto"
                    suggestions={{ sug: productos }}
                    path={{ path: "sug", value: "value", label: "label" }}
                    placeholder="Producto"
                    validate={requiredValidation}
                    disabled={disabled}
                    required
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  style={{ marginTop: "4px", paddingRight: "4px" }}
                >
                  <InputNumberGtZeroField
                    name={`${detalle}.cantidad`}
                    label="Cantidad"
                    disabled={disabled}
                    type={"int"}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={3} style={{ marginTop: "4px" }}>
                  <InputTextField
                    name={`${detalle}.observacion`}
                    label="Observación"
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
            </Paper>
            {!disabled && (
              <Tooltip title="Quitar item" placement="right-start">
                <Icon
                  color="action"
                  onClick={() => fields.splice(index, 1)}
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    zIndex: 999,
                    backgroundColor: "white",
                    borderRadius: "50%",
                    cursor: "pointer"
                  }}
                >
                  remove_circle
                </Icon>
              </Tooltip>
            )}
          </Grid>
        </Fragment>
      );
    })}
  </Grid>
);

class Form extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      history,
      errors,
      disabled,
      productos,
      clientes,
      isMisVentas,
      openDialog,
      idPrograma,
      inBackOffice,
      idVenta
    } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {idVenta ? "Ver venta" : "Nueva venta"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form className={classes.container} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ul className={classes.error}>
                  {errors &&
                    errors.map((e, index) => <li key={index}>{e.message}</li>)}
                </ul>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={5} lg={4}>
                <InputTextField
                  name="numeroFactura"
                  label="N° de factura"
                  disabled={disabled}
                  required
                  style={{ marginTop: "4px", paddingRight: "9px" }}
                />
              </Grid>
              <Grid item xs={8} md={5} lg={4}>
                {clientes && (
                  <ReduxTypeAhead
                    name="idCliente"
                    label="Cliente"
                    suggestions={{ sug: clientes }}
                    path={{ path: "sug", value: "value", label: "label" }}
                    placeholder="Cliente"
                    validate={[]}
                    disabled={disabled}
                  />
                )}
              </Grid>

              {!disabled && (
                <Grid
                  item
                  xs={12}
                  md={2}
                  lg={4}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: window.innerWidth <= 957 ? "10px" : "0px"
                  }}
                >
                  <Button
                    type="button"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    onClick={() => openDialog()}
                  >
                    Nuevo cliente
                  </Button>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                style={{
                  marginTop: window.innerWidth <= 576 ? "10px" : "20px",
                  marginBottom: window.innerWidth <= 576 ? "10px" : "20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px"
                }}
              >
                <Typography variant="h6" style={{ margin: 0 }}>
                  Detalles de la venta
                </Typography>
                {productos &&
                  productos.length !== 0 &&
                  !disabled && (
                    <Tooltip title="Agregar item" placement="right-end">
                      <Icon
                        color="secondary"
                        onClick={() => this.props.array.push("fields", {})}
                        style={{ cursor: "pointer" }}
                      >
                        add_circle
                      </Icon>
                    </Tooltip>
                  )}
              </Grid>

              <Grid item xs={12}>
                <FieldArray
                  name="fields"
                  component={renderDetallesVenta}
                  productos={productos}
                  disabled={disabled}
                />
              </Grid>

              <Grid
                className={classes.gridButton}
                item
                xs={12}
                style={{
                  marginBottom: "210px",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px"
                }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  className={classes.button}
                  onClick={() =>
                    history.push(
                      inBackOffice
                        ? `/admin/programa/${idPrograma}/ventas-retail`
                        : isMisVentas
                          ? `/mis-ventas`
                          : `/ventas`
                    )
                  }
                >
                  {idVenta ? "Volver" : "Cancelar"}
                </Button>
                {(!idVenta || idVenta === "new") && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    disabled={pristine || submitting || disabled}
                  >
                    Guardar
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

Form = reduxForm({
  form: "formVentaRetail",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(Form);

Form = connect(
  state => ({
    formMeta: getFormMeta("formVentaRetail")(state)
  }),
  dispatch => ({
    changeFieldValue: (field, value) =>
      dispatch(change("formVentaRetail", field, value))
  })
)(Form);

export default withRouter(withStyles(styles)(Form));
